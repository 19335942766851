<template>
  <TinyMceEditor
    id="input-editor"
    :value="value"
    :init="options"
    @input="update"
  />
</template>

<script>
// tiny mce.
import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import TinyMceEditor from '@tinymce/tinymce-vue';
import 'tinymce/themes/silver';

// tiny mce plugins.
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/link';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/print';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/help';
import 'tinymce/plugins/table';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/autosave';

export default {
  name: 'Editor',
  components: {
    TinyMceEditor
  },
  props: {
    value: String
  },
  data() {
    return {
      options: {
        language_url: '/content/scripts/tinymce/langs/sv_SE.js',
        language: 'sv_SE',
        skin_url: '/content/scripts/tinymce/skins/ui/oxide',
        height: 500,
        plugins: [
          'lists advlist',
          'link autolink',
          'table',
          'fullscreen',
          'print preview',
          'autosave'
        ],
        toolbar: [
          'undo redo | bold italic underline strikethrough | fontsizeselect formatselect',
          'alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | fullscreen  preview save  | link'
        ],
        branding: false,
        menubar: false
      }
    };
  },
  methods: {
    update(newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>
